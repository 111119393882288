<template>
<div class="bg-white">
  <NavBarNew></NavBarNew>
  <CourseHeroe></CourseHeroe>
  <CoursesDescription></CoursesDescription>
  <WorkKit></WorkKit>
  <OurCurses></OurCurses>
  <Footer></Footer>
  </div>
</template>

<script>
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import CourseHeroe from "@/components/CourseDescriptionScreen/CourseHeroe.vue";
import CoursesDescription from "@/components/CourseDescriptionScreen/CoursesDescription.vue";
import WorkKit from "@/components/CourseDescriptionScreen/WorkKit.vue";
import OurCurses from "@/components/HomeScreen/ourcurses.vue";
import Footer from "@/components/HomeScreen/Footer.vue";

export default {
  name: "Our Courses",
  components: {
    NavBarNew,
    CourseHeroe,
    CoursesDescription,
    WorkKit,
    OurCurses,
    Footer,
  },
};
</script>