<template>
  <div class="grid md:grid-cols-4 md:grid-row-4 pt-36 coursesdecoSvg1">
    <div
      class="md:col-span-3 md:row-span-4 coursesHeroeBg1 md:w-full md:h-full"
    >
      <div class="coursesHeroeBg2 w-full h-full"></div>
    </div>
    <div class="md:row-span-4 pt-24 px-2">
      <div class="border-StemPurple border-l-2 pl-4 md:w-56">
        <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
          Për kursin
        </h1>
      </div>
      <h1 class="text-StemYellow font-bold text-4xl pr-4 text-left pt-5">
        {{ selectedCourse.title }}
      </h1>
      <div class="md:w-60">
        <p 
          class="
            text-left
            pt-10
            text-StemTextSecond
            font-normal
            text-lg
            leading-loose
            px-2
          "
        >
          {{ selectedCourse.shortDesc }}
        </p>
      </div>
      <div class="md:float-left text-center pt-8">
        <router-link to="/register">
        <button
          class="
            rounded-lg
            bg-StemYellow
            hover:bg-transparent hover:text-StemYellow
            text-white
            border
            hover:border-StemYellow
            text-xl
            font-normal
            shadow-2xl
            px-10
            py-4
          "
        >
          Regjistrohu
        </button>
        </router-link>
      </div>
    </div>
  </div>
  <div
    class="
      grid
      place-items-start
      px-2
      md:grid-cols-3 md:grid-row-2 md:place-items-start md:gap-y-12
      pt-20
      md:px-28
    "
  >
    <!-- <coursescardsmall v-for="i in items" :key="i" :imageAvatars="i" :data="i"></coursescardsmall> -->

    <div class="grid grid-flow-col gap-x-6 place-items-center">
      <div class="">
        <img src="@/assets/Svg/CoursesSvgSmall.svg" alt="" />
      </div>
      <div class="">
        <h1 class="text-gray-900 text-lg font-bold leading-6 text-left">
          Regjistrimet
        </h1>
        <p class="text-gray-900 text-xs font-normal leading-5 text-left">
          Hapen më: {{ selectedCourse.registration }}
        </p>
      </div>
    </div>
    <div class="grid grid-flow-col gap-x-6 place-items-center">
      <div class="">
        <img src="@/assets/Svg/small2.svg" alt="" />
      </div>
      <div class="">
        <h1 class="text-gray-900 text-lg font-bold leading-6 text-left">
          Kohëzgjatja e kursit
        </h1>
        <p class="text-gray-900 text-xs font-normal leading-5 text-left">
          {{ selectedCourse.durationWeek }} javë
        </p>
      </div>
    </div>
    <div class="grid grid-flow-col gap-x-6 place-items-center">
      <div class="">
        <img src="@/assets/Svg/small3.svg" alt="" />
      </div>
      <div class="">
        <h1 class="text-gray-900 text-lg font-bold leading-6 text-left">
          Organizimi i mësimit
        </h1>
        <p class="text-gray-900 text-xs font-normal leading-5 text-left">
          {{selectedCourse.durationHour}} në javë.
        </p>
      </div>
    </div>
    <div class="grid grid-flow-col gap-x-6 place-items-center">
      <div class="">
        <img src="@/assets/Svg/small4.svg" alt="" />
      </div>
      <div class="">
        <h1 class="text-gray-900 text-lg font-bold leading-6 text-left">
          Terminet
        </h1>
        <p class="text-gray-900 text-xs font-normal leading-5 text-left">
          Sipas marrëveshjes me prindërit.
        </p>
      </div>
    </div>
    <div class="grid grid-flow-col gap-x-6 place-items-center">
      <div class="">
        <img src="@/assets/images/BookIconPNG.png" alt="" />
      </div>
      <div class="">
        <h1 class="text-gray-900 text-lg font-bold leading-6 text-left">
          Mjete mësimore
        </h1>
        <p class="text-gray-900 text-xs font-normal leading-5 text-left">
          {{selectedCourse.extracost}}
        </p>
      </div>
    </div>
    <div class="grid grid-flow-col gap-x-6 place-items-center">
      <div class="">
        <img src="@/assets/Svg/small6.svg" alt="" />
      </div>
      <div class="">
        <h1 class="text-gray-900 text-lg font-bold leading-6 text-left">
          Çmimi për 4 javë:
        </h1>
        <p class="text-yellow-600 text-xs font-normal leading-5 text-left">
          {{ selectedCourse.price }} DEN
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import coursescardsmall from "./../../components/base/coursescardsmall.vue";
// import coursessmall from "@/data/coursessmall.js"
import courses from "@/data/courses.js";
export default {
  // components: { coursescardsmall },
  data() {
    return {
      items: courses.data,
    };
  },
  computed: {
    selectedCourse() {
      const id = this.$route.params.id;
      let course = courses.data.filter((c) => c.id === +id);
      return course[0];
    },
  },
};
</script>

<style>
.coursesHeroeBg1 {
  background-image: url("./../../assets/images/CoursesBg1.png");
  background-repeat: no-repeat;
}
.coursesHeroeBg2 {
  background-image: url("./../../assets/images/CoursesHeroeImg.png");
  background-repeat: no-repeat;
  background-position-x: 22%;
  background-position-y: 10%;
}
.coursesdecoSvg1 {
  background-image: url("./../../assets/Svg/CoursesDecoSvg.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 35%;
}
</style>