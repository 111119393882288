<template>
  <div class="md:pt-10 WorkKitbg1">
    <div class="WorkKitbg2">
      <div class="grid grid-rows-1 md:grid-cols-2">
        <div class="md:pl-32 md:pt-20 px-5 pt-20">
            <img :src="selectedCourse.image2" alt="">
        </div>
        <div class="WorkKitDeco1 h-96">
          <div class=" pt-10 md:pt-20 md:pr-20 px-2">
            <div class="border-StemPurple border-r-2 pr-4">
              <h1 class="text-StemBlue font-bold text-4xl pr-4 text-right pb-5">
                Shkathtësitë
              </h1>
            </div>
            <div v-for="shit in selectedCourse.skills" :key="shit"
              class="
                text-gray-400
                font-normal
                text-sm
                leading-loose
                text-left
                md:text-right
                md:pt-3 
                px-2
                h-min
              "
            >
             {{shit}}
              
            </div>
            <!-- <div class="grid grid-rows-2 md:grid-cols-2 md:py-40 md:place-items-center">
              <div class=""></div>
              <div class="">
                <button
                  class="
                    rounded-lg
                    bg-StemYellow
                    hover:bg-transparent hover:text-StemYellow
                    text-white
                    border
                    hover:border-StemYellow
                    text-xl
                    font-normal
                    shadow-2xl
                    px-10
                    py-4
                  "
                >
                  Regjistrohu
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import courses from "@/data/courses.js";
  export default {
  // components:{Tabs},
  // props: ["data"],

  data() {
    return {
    courses:courses.data,
    };
  },
  computed: {
    selectedCourse() {
      const id = this.$route.params.id;
      let course = courses.data.filter((c) => c.id === +id);
      return course[0];
    },
  },

};
</script>

<style>
/* YourWorkKit.svg
WorkKitbg.svg
 */

@media only screen and (min-width: 1025px) {
.WorkKitbg1 {
  background-image: url("./../../assets/Svg/YourWorkKit.svg");
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 20%;
}
.WorkKitbg2 {
  background-image: url("./../../assets/Svg/WorkKitbg.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
}

/* .WorkKitbg3 {
  background-image: url("./../../assets/images/WorkKitBg2.png");
  background-repeat: no-repeat;
  background-position-x: 20%;
  background-position-y: 180%;
} */
.WorkKitDeco1 {
  background-image: url("./../../assets/Svg/WorkKitDeco.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
}
}
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 640px) {
  .WorkKitbg1 {
  background-image: url("./../../assets/Svg/YourWorkKit.svg");
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 20%;
}
.WorkKitDeco1 {
  background-image: url("./../../assets/Svg/WorkKitDeco.svg");
  background-repeat: no-repeat;
  background-position-x: 10%;
  background-position-y: top;
}
.WorkKitbg2 {
  background-image: url("./../../assets/Svg/WorkKitbg.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
}
/* .WorkKitbg3 {
  background-image: url("./../../assets/images/WorkKitBg2.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40%;
  background-size: 85%;
} */

}
</style>