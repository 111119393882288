<template>
  <div class="">
    <div class="md:px-24 pt-32 wedo20bg px-2">
      <div class="border-StemPurple border-l-2 pl-4">
        <h1
          class="
            text-StemBlue
            font-bold
            text-4xl
            pr-4
            text-left
            DescriptionDeco1
          "
        >
          Çka do të mësohet?
        </h1>
      </div>
    </div>
    <!-- <Tabs class=""></Tabs> -->
    <!-- TAB -->
    <div
      class="
        grid grid-rows-3 grid-cols-3
        md:grid-cols-5 md:grid-rows-1
        px-2
        md:px-24
        pt-20
      "
    >
      <div
        class="
          row-start-2 row-span-2
          col-span-3
          md:col-start-1 md:col-span-4 md:row-start-1
        "
      >
        <div
          class="
            text-left
            leading-loose
            text-StemTextSecond
            font-normal
            text-lg
            md:pr-40
            px-2
          "
        >
          <div class="" v-for="tab in tabs" :key="tab" v-show="tab.isActive">
                      <div class="" v-for="el in tab.desc" :key="el">

                          {{el}}
                      </div>
          </div>
        </div>
      </div>

      <div class="row-start-1  md:col-start-5">
        <div>
          <ul class="grid grid-flow-col gap-5 px-4 md:grid-flow-row">
            <li
              class="text-right"
              v-for="tab in tabs"
              :key="tab"
              :class="{ 'is-active': tab.isActive }"
            >
              <button
                @click="selectTab(tab)"
                class="
                  md:col-start-5
                  row-start-1
                  
                  text-center
                  md:text-right 
                  text-StemBlue
                  font-bold
                  text-xl
                  md:text-2xl
                  py-2
                  w-full
                "
              >
                <a class="cursor-pointer" :href="tab.href">{{ tab.name }}</a>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- ENDTAB -->
  </div>
</template>

<script>
// import Tabs from '@/components/base/tabs/tabs.vue'
import courses from "@/data/courses.js";

export default {
  // components:{Tabs},
  // props: ["data"],

  data() {
    return {
      tabs: [
        {
          name: "Përshkrimi i kursit",
          desc: "",
          isActive: true,
        },
        { name: "Qëllimi i kursit", desc: "", isActive: false },
        // { name: "Shkathtësitë", desc: "", isActive: false },
      ],
    };
  },
  methods: {
    selectTab: function (selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name == selectedTab.name;
      });
    },
  },
  computed: {
    href() {
      return "#" + this.name.toLowerCase().replace(/ /g, "-");
    },
    selectedCourse() {
      const id = this.$route.params.id;
      let course = courses.data.filter((c) => c.id === +id);
      return course[0];
    },
  },
  mounted() {
    this.tabs[0].desc = this.selectedCourse.longDesc;
    this.tabs[1].desc = this.selectedCourse.purpose;
    // this.tabs[2].desc = this.selectedCourse.skills;
  },
};
</script>

<style>
@media only screen and (min-width: 1025px) {
  .wedo20bg {
    background-image: url("./../../assets/Svg/WeDo2.0.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }
  .DescriptionDeco1 {
    background-image: url("./../../assets/Svg/DescriptionDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: bottom;
  }
}
@media only screen and (max-width: 1024px) {
  .wedo20bg {
    background-image: url("./../../assets/Svg/WeDo2.0.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }
  .DescriptionDeco1 {
    background-image: url("./../../assets/Svg/DescriptionDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;
  }
}
@media only screen and (max-width: 640px) {
  .DescriptionDeco1 {
    background-image: url("./../../assets/Svg/DescriptionDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
}
.is-active{
  border-bottom: 8px solid ;
  border-bottom-color: #233558;
}
</style>




